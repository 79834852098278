// import React, {useState} from 'react';
// import { Link } from 'react-scroll'
// import logo from "../assests/Logos.png";
// import { MenuIcon, XIcon } from '@heroicons/react/outline';
// import "../pages/Navbar.css";
// // import pdftest from "../pdf/pdf-test.pdf";

// const Navbar = () => {
//     const [nav, setNav] = useState(false)
//     const handleClick = () => setNav(!nav)

//     const handleClose =()=> setNav(!nav)

//   return (
//     <div className='w-screen h-[80px] z-10 navbar-bg fixed drop-shadow-lg'>
//       <div className='px-2 flex justify-between items-center w-full h-full'>
//           <div className='flex items-center'>
//             <Link to="home" smooth={true} duration={500}>
//                 <img src={logo} alt="Logo" />
//             </Link>
//           </div>
//           <div className='flex items-center justify-center'>
//                 <ul className='hidden md:flex '>
//                   <li><Link to="home" smooth={true} duration={500}>HOME</Link></li>
//                   <li><Link to="aboutus" smooth={true} offset={-200} duration={500}>ABOUT US</Link></li>
//                   <li><Link to="whitepaper" smooth={true} offset={-50} duration={500}>WHITEPAPER</Link></li>
//                   <li><Link to="team" smooth={true} offset={-100} duration={500}>TEAM</Link></li>
//                   <li><Link to="contactus" smooth={true} offset={-50} duration={500}>CONTACT US</Link></li>
//                 </ul>
//           </div>
//           <div className='hidden md:flex pr-4'>
//             <button className=' dapp'>LAUNCH DAPP</button>
//           </div>
//           <div className='md:hidden mr-4' onClick={handleClick}>
//               {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}
            
//           </div>
//       </div>

//       <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8 text-blue-800'}>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="home" smooth={true} duration={500}>HOME</Link></li>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="aboutus" smooth={true} offset={-200} duration={500}>ABOUT US</Link></li>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="whitepaper" smooth={true} offset={-50} duration={500}>WHITEPAPER</Link></li>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="team" smooth={true} offset={-100} duration={500}>TEAM</Link></li>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="contactus" smooth={true} offset={-50} duration={500}>CONTACT US</Link></li>

//         <div className='flex flex-col my-4'>
//             <button className='dapp'>LAUNCH DAPP</button>
//         </div>
//       </ul>
//     </div>
//   );
// };

// export default Navbar;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import logo from "../assests/Logos.png";
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import "../pages/Navbar.css";
import pdftest from "../pdf/pdf-test.pdf";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(!nav);

  const [walletConnected, setWalletConnected] = useState(false);
  
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        setWalletConnected(true);
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    } else {
      console.error('MetaMask not detected.');
    }
  };

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length > 0) {
        setWalletConnected(true);
      } else {
        setWalletConnected(false);
      }
    };
  
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }
  
    return () => {
      if (window.ethereum && window.ethereum.off) {
        window.ethereum.off('accountsChanged', handleAccountsChanged);
      }
    };
  }, []);

  return (
    <div className='w-screen h-[80px] z-10 navbar-bg fixed drop-shadow-lg'>
      <div className='px-2 flex justify-between items-center w-full h-full'>
        <div className='flex items-center'>
          <Link to="home" smooth={true} duration={500}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className='flex items-center justify-center'>
          <ul className='hidden md:flex '>
            <li><Link to="home" smooth={true} duration={500}>HOME</Link></li>
            <li><Link to="aboutus" smooth={true} offset={-200} duration={500}>ABOUT US</Link></li>
            <li><a href={pdftest} target="_blank" rel="noopener noreferrer" >WHITEPAPER</a></li>
            <li><Link to="team" smooth={true} offset={-100} duration={500}>TEAM</Link></li>
            <li><Link to="contactus" smooth={true} offset={-50} duration={500}>CONTACT US</Link></li>
          </ul>
        </div>
        <div className='hidden md:flex pr-4'>
          {walletConnected ? (
            <span>Wallet Connected</span>
          ) : (
            <button className='dapp' onClick={connectWallet}>
              {walletConnected ? 'Connected' : 'LAUNCH DAPP'}
            </button>
          )}
        </div>
        <div className='md:hidden mr-4' onClick={handleClick}>
          {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}
        </div>
      </div>

      <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8 text-blue-800'}>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="home" smooth={true} duration={500}>HOME</Link></li>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="aboutus" smooth={true} offset={-200} duration={500}>ABOUT US</Link></li>
        <li className='border-b-2 border-zinc-300 w-full'><a href={pdftest} target="_blank" rel="noopener noreferrer" onClick={handleClose}>WHITEPAPER</a></li>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="team" smooth={true} offset={-100} duration={500}>TEAM</Link></li>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="contactus" smooth={true} offset={-50} duration={500}>CONTACT US</Link></li>
        <div className='flex flex-col my-4'>
            <button className='dapp' onClick={connectWallet}>
                 {walletConnected ? 'Connected' : 'LAUNCH DAPP'}
            </button>        
          </div>
      </ul>
    </div>
  );
};

export default Navbar;
