import React from 'react';
import girl from '../assests/circlegirl.png';
import "./mostpopular.css";

const Mostpopular = () => {
  return (
    <div name="mostpopular" className="Mostpopular-bg p-4 ">
      <div className="flex flex-center justify-center md:items-start w-full py-12">
        <h1 className="text-xl md:text-6xl text-center font-face-atures text-white ">
          MOST POPULAR OUR<br />NFTs.{' '}
        </h1>
      </div>
      <div className="flex flex-center card-container">
        <div className=" cardmost">
          <div className="centered-content p-8">
            <div className="image-with-text">
              <img src={girl} alt="NFT" className="image-in-text " />
              <div className="text-content ">
                <div className='bg-girl p-4'>
                <h3 className="text-2xl text-white font-face-atures">ARANORMA</h3>
                <p className="text-white text-xl">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.<br/> Excepturi provident iure placeat blanditiis ea sint earum hic iste <br/>quibusdam exercitationem.
                </p>
                </div>
                <p className="flex items-center text-white font-face-atures collect-btn">Collect Now</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-center card-container">
        <div className=" cardmost">
          <div className="centered-content p-8">
            <div className="image-with-text">
              
              <div className="text-content ">
                <div className='bg-girl p-4'>
                <h3 className="text-2xl text-white font-face-atures">ARANORMA</h3>
                <p className="text-white text-xl">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.<br/> Excepturi provident iure placeat blanditiis ea sint earum hic iste <br/>quibusdam exercitationem.
                </p>
                </div>
                <div className='collect-left'><p className="flex text-white font-face-atures ">Collect Now</p></div>
              </div>
              <img src={girl} alt="NFT" className="image-in-text " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mostpopular;
