import './App.css';
import Navbar from './pages/Navbar';
import Home  from './pages/Home';
import Image from './pages/Hero';
import About from "./pages/About";
import Discover from './pages/Discover';
import Mostpopular from './pages/Mostpopular';
import Footer from './pages/Footer';
import Lets from './pages/Lets';

function App() {
  return (
    <div>
         <Navbar />
         <Home />
         <Image />
         <About />
         <Discover />
         <Mostpopular />
         <Lets />
         <Footer/>
    </div>
  );
}

export default App;
