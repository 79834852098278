import React from 'react'
import bgimage from "../assests/imagesection.png"

const Hero = () => {
  return (
    <div name='hero'>
      <img src={bgimage} className="w-full h-auto object-cover" alt="Section Background" />
    </div>
  )
}

export default Hero
