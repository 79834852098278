import React from 'react'
import "../App.css";
import girl from '../assests/girl.png'

const Discover = () => {
  return (
    <div name='team' className='discover-bg p-4 '>
            <div className='flex flex-center justify-center md:items-start w-full '>
                <h1 className=' text-2xl md:text-6xl text-center font-face-atures text-white pt-10 '>
                        DISCOVER,PLAY COLLECT <br/>AND SELL OUR NFTs. </h1>
            </div>
            <div className='grid md:grid-cols-2 max-w-[1240px] m-auto'>
                    <div className='flex flex-col justify-center md:items-start w-full '>
                        <img src={girl} />
                    </div>
                    <div className='flex flex-col justify-center md:items-end w-full  text-white '>
                        <h1 className='py-3 md:text-2xl  font-face-atures '>ARA NORMA</h1>
                        <div className=' text-justify'>
                            <p className='text-xl py-4 '>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna.Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus.</p>
                            <p className='text-xl '>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna.Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus.</p>
                        </div>
                        <div className='font-face-atures '>
                         <p className=' my-6 collect-btn md:items-end'>COLLECT NOW</p>
                        </div>
                    </div>
            </div>
    </div>
  )
}

export default Discover;