import React from 'react';
import {
    FaFacebook,
    FaGithub,
    FaInstagram,
    FaMedium,
    FaTwitter,
} from 'react-icons/fa';
import logo from "../assests/logo.png"
const Footer = () => {
  return (
    <footer className="bg-footer p-6">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* First Column */}
        <div className="flex flex-col">
          <div className="flex items-center mb-4">
            <img src={logo} alt="Logo" className="h-8 mr-4" />
          </div>
          <div className="flex items-center">
            <p className="text-white">Lorem ipsum dolor sit amet,</p>
          </div>
        </div>

        {/* Second Column */}
        <div className="flex items-center">
          <p className="text-white">© 2022 | Ara Norma Project. All rights reserved.</p>
        </div>

        {/* Third Column */}
        <div className="flex flex-col items-center ">
          <div className="mb-4 justify-end">Social Connect</div>
          <div className="flex items-center">
            <a href="#" className="mr-4">
              <FaFacebook />
            </a>
            <a href="#" className="mr-4">
              <FaInstagram />
            </a>
            <a href="#" className="mr-4">
              <FaTwitter />
            </a>
            <a href="#" className="mr-4">
              <FaMedium />
            </a>
            <a href="#">
              <FaGithub />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;