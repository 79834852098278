import React, {useState}from 'react'
import "../App.css";
import axios from "axios";

const Lets= () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [inquiryType, setInquiryType] = useState("General Inquiry");
  const [message, setMessage] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('http://localhost:8000/insert', {
        fullName: name,
        emailId: email,
        phoneNumber: phone,
        inquiryType: inquiryType,
        message: message
      });
      
      setSubmitMessage("Data submitted successfully.");
      setName("");
      setEmail("");
      setPhone("");
      setInquiryType("General Inquiry");
      setMessage("");
    } catch (error) {
      console.error(error);
      setSubmitMessage("An error occurred while submitting the data.");
    }
  };



  return (
    
    <div name='contactus' className='w-full h-screen  flex flex-col justify-between bg-lets'>
    <div className='md:p-8 md:m-8'>
        <div className='grid md:grid-cols-2 max-w-[1240px] m-auto'>
            <div className='flex flex-center justify-center md:items-center w-full md:px-2 py-8'>
                <h1 className='py-3 text-2xl md:text-6xl  text-white font-face-atures'>LETS GET <br/>IN TOUCH <br/>TODAY</h1>
            </div>
           <div className=' bg-cardmsg shadow-lg p-8 m-8'>
           <div className="">
           <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/2">
                <div className="relative">
                  
                  <input
                  placeholder='FULL NAME'
                    type="text"
                    value={name}
                    className="w-full bg-name  py-2 px-3 Name" required
                    onChange={(e) => {setName(e.target.value)}}
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  
                  <input
                  placeholder=' EMAIL ADDRESS'
                    type="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    className="w-full bg-name  py-2 px-3 Email" required
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  
                  <input
                  placeholder='  PHONE NUMBER'
                    type="tel"
                    value={phone}
                    onChange={(e) => {setPhone(e.target.value)}}
                    className="w-full bg-name  py-2 px-3 Phone" required
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  
                <select
                      placeholder='SELECT INQUIRY'
                      value={inquiryType}
                      onChange={(e) => {setInquiryType(e.target.value)}}
                        className="w-full bg-name  py-2 px-3 InquiryType" required
                      >
                      <option value="" disabled className="text-white">
                          SELECT INQUIRY
                      </option>
                        <option value="General Inquiry" className="bg-blue-500 text-white">General Inquiry</option>
                        <option value="Sales" className="bg-blue-500 text-white">Sales</option>
                        <option value="Technical Support" className="bg-blue-500 text-white">Technical Support</option>
                    </select>
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  
                  <textarea
                  placeholder='HOW CAN WE HELP YOU?'
                  value={message}
                  onChange={(e) => {setMessage(e.target.value)}}
                    className="w-full bg-name text-white h-32 text-white py-4 px-4 Message" required
                  ></textarea>
                </div>
              </div>
              <div className="p-2 w-1/2">
                <button type="submit" className="flex text-white bg-sendmsg  py-2 px-8  rounded ">
                  SEND MESSAGE
                </button>
              </div>
              </div>
            </form>
            {submitMessage && <p className='text-white'>{submitMessage}</p>}
           </div>
            </div>
            </div>
    </div>
    </div>
  )
}
export default Lets
