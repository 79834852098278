import "../App.css";
import React from 'react'

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-zinc-200 flex flex-col justify-between home-bg'>
        <div className='grid md:grid-cols-2 max-w-[1240px] m-auto p-6'>
            <div className='flex flex-col justify-center md:items-start w-full px-4 py-16  text-white'>
                <h1 className='py-3 text-5xl md:text-7xl font-face-atures'>Lorem ipsum dolor sit amet,</h1>
                <p className='text-xl my-6'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna.Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus.</p>
                <button className='my-6 p-2 get-btn '>Get Started</button>
            </div>
        </div>
    </div>
  )
}
export default Home