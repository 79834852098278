import React from 'react';
import AboutImg from '../assests/BG3.png';
import "../App.css";
import globe from "../assests/globe.png";

const About = () => {
  return (
  <div name='aboutus' className='w-full bg-about-bg'>
     
      <div className='max-w-[1240px] mx-auto text-white relative'>
          <div className='px-4 py-12'>
              <h2 className='text-3xl pt-8  text-center font-face-atures'>ABOUT US</h2>
              <p className='py-4 text-xl text-center font-face-avenir'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, <br/>magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna.<br/>Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus.</p>
          </div>

    <div className='grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-14 pt-12 sm:pt-20 '>
      <div className='bg-globe text-white'>
          <div className='p-8 '>
          <div  className='w-16 p-1  mt-[-4rem]' ><img src={globe}/></div>
              <p className=' text-xl'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi provident iure placeat blanditiis ea sint earum hic iste quibusdam exercitationem.</p>
          </div>
          
      </div>
      <div className='bg-globe text-white'>
          <div className='p-8'>
          <div  className='w-16 p-1  mt-[-4rem]' ><img src={globe}/></div>
              <p className=' text-xl'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi provident iure placeat blanditiis ea sint earum hic iste quibusdam exercitationem.</p>
          </div>
          
      </div>
      <div className='bg-globe text-white'>
          <div className='p-8'>
              <div  className='w-16 p-1  mt-[-4rem]' ><img src={globe}/></div>
              <p className='text-xl'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi provident iure placeat blanditiis ea sint earum hic iste quibusdam exercitationem.</p>
          </div>
          
      </div>
      </div>
    </div>
  </div>
  );
};

export default About;
